<template>
  <div class="google-login-page">redirect to...</div>
</template>

<script>
import { cccvLogin } from "@/common/api.service";
import { setAuth } from "@/common/auth.service";

export default {
  name: "Google",
  async mounted() {
    try {
      const accessToken = this.$route?.query?.access_token;
      const idToken = this.$route?.query?.id_token;
      if (!accessToken || !idToken) {
        throw new Error();
      }

      // access_token, idToken이 들어오면 API를 호출해서 CCCV에 로그인을 한다.
      const login = await cccvLogin("google", {
        access_token: accessToken,
        idToken
      });

      if (!login || !login.data) {
        throw new Error();
      }

      setAuth(login.data);
      this.$router.push("/admin/user");
    } catch (err) {
      alert("로그인에 실패하였습니다.");
      window.location.href = "/";
    }
  }
};
</script>

<style></style>
